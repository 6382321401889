import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getMarkets } from "../../repository/MarketRepository";
import { Tab } from "@headlessui/react";
import Chart_b from "../../assets/imgs/chart_b.png";
import Close_b from "../../assets/imgs/close_b.png";
import Chat1 from "../../assets/imgs/play_now.png";
import Main from '../../layouts/Auth.jsx';
import { useNavigate } from "react-router-dom";

const KalyanAndDesawar = ({
  tabBorderColor,
  tabBG,
  activeTabBgColor,
  vertialyPadding,
  tabHeight,
}) => {
  const [marketsData, setMarketData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(() => {
    return localStorage.getItem("selectedTab") || "general";
  });
  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false); // State for authentication modal

  const token = localStorage.getItem("authToken");

  const marketType = selectedTab === "general" ? "general" : "desawar";
  const marketTypeChart = selectedTab === "general" ? "market" : "desawar";
  const marketTypeSlug = selectedTab === "general" ? "pana-chart" : "chart";

  useEffect(() => {
    const fetchMarkets = async () => {
      setLoading(true);
      try {
        const response = await getMarkets(marketType);
        setMarketData(response?.data?.response?.markets);
      } catch (error) {
        console.error("Error fetching markets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarkets();
  }, [selectedTab]);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  const handleProtectedClick = (e, marketId) => {
    if (!token) {
      e.preventDefault();
      localStorage.setItem("authMenu", 1);
      setAuthModalOpen(true); // Show authentication modal if not logged in
    } else {
      // Your other conditions here if the user is authenticated
      const url = `https://api.mahakalmatka.com/${marketTypeChart}/${marketTypeSlug}/${marketId}/hello`;
      window.open(url, "_blank");
    }
  };


  const handlekalyanChatClick = (market) => {
    if (!token) {
      localStorage.setItem("authMenu", 1)
      setAuthModalOpen(true); // Open modal for authentication
    } else {
      // Use navigate to redirect in the same tab
      navigate(`/general-sub-games?gameType=${market.name}&market_id=${market.id}`);
    }
  };
  const handleChatClick = (market) => {
    if (!token) {
      localStorage.setItem("authMenu", 1)
      setAuthModalOpen(true); // Open modal for authentication
    } else {
      // Use navigate to redirect in the same tab
      navigate(`/play-game?gameType=desawar&market_id=${market.id}`);
    }
  };
  return (
    <div className="px-1 pb-8">
      <Tab.Group
        selectedIndex={selectedTab === "general" ? 0 : 1}
        onChange={(index) =>
          setSelectedTab(index === 0 ? "general" : "desawar")
        }
      >
        {/* Tab List (Tabs remain visible even during loading) */}
        <Tab.List
          className={`flex p-1 space-x-1 rounded-xl border-2 w-[250px] m-auto ${tabBorderColor} ${tabBG}`}
        >
          <Tab
            className={({ selected }) =>
              selected
                ? `${activeTabBgColor} shadow text-white font-semibold ${vertialyPadding} px-4 rounded-lg w-[49%]`
                : `text-white font-semibold ${vertialyPadding} px-4 rounded-lg w-[49%] ${tabHeight}`
            }
          >
            Kalyan
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? `${activeTabBgColor} shadow text-white font-semibold ${vertialyPadding} px-4 rounded-lg w-[49%]`
                : `text-white font-semibold ${vertialyPadding} px-4 rounded-lg w-[49%] ${tabHeight}`
            }
          >
            Desawar
          </Tab>
        </Tab.List>

        {/* Tab Panels (Show loading spinner here if loading is true) */}
        <Tab.Panels className="mt-1">
          {loading ? (
            <div className="flex justify-center items-center h-[60vh]">
              <BeatLoader color="#0098c7" loading={loading} />
            </div>
          ) : (
            <>
              <Tab.Panel className="flex flex-col gap-1">
                {marketsData
                  ?.filter((market) => market !== null)
                  .map((market) => (
                    <div
                      key={market.id}
                      className={`flex flex-col text-white rounded-md border-2 ${tabBorderColor}`}
                    >
                      <div className="w-full flex justify-between items-center p-1 px-3 bg-primary">
                        <p className="text-[12px]">Open: {market.open_time}</p>
                        <p className="text-[12px]">Close: {market.close_time}</p>
                      </div>

                      <div className="p-2 w-full flex justify-between items-center">
                        <img
                          src={Chart_b}
                          alt="Chart"
                          className="w-[50px] h-[50px] object-cover cursor-pointer my-[-5px]"
                          onClick={(e) => handleProtectedClick(e, market.id)}
                        />

                        <div className="flex flex-col justify-center items-center">
                          <span className="text-[13px] font-semibold uppercase text-[#4f4f4f]">
                            {market.name}
                          </span>
                          <span className="text-[12px] font-semibold uppercase text-primary">
                            {market.last_result
                              ? market.last_result.result
                              : "***-**-***"}
                          </span>
                          <span
                            className={`text-[12px] ${!market.game_on
                              ? "text-orange"
                              : "text-greenLight"
                              }`}
                          >
                            {market.game_on
                              ? "Market is Running"
                              : "Market is Close"}
                          </span>
                        </div>
                        <div
                          className={`w-[50px] h-[50px] text-center font-semibold rounded-full`}
                        >
                          {!market.game_on ? (
                            <div style={{ pointerEvents: "none" }}>
                              <img
                                src={Close_b}
                                alt="Close"
                                className="w-full h-full object-cover cursor-not-allowed"
                              />
                            </div>
                          ) : (
                            <img
                              src={Chat1}
                              alt="Play Now"
                              className="w-full h-full object-cover cursor-pointer"
                              onClick={() => handlekalyanChatClick(market)} 
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </Tab.Panel>
              <Tab.Panel className="flex flex-col gap-1">
                {marketsData
                  ?.filter((market) => market !== null)
                  .map((market) => (
                    <div
                      key={market.id}
                      className={`flex flex-col text-white rounded-md border-2 ${tabBorderColor}`}
                    >
                      <div className="w-full flex justify-between items-center p-1 px-3 bg-primary">
                        <p className="text-[12px]">Open: {market.open_time}</p>
                        <p className="text-[12px]">Close: {market.close_time}</p>
                      </div>
                      <div className="p-2 w-full flex justify-between items-center">
                        <img
                          src={Chart_b}
                          alt="Chart"
                          className="w-[50px] h-[50px] object-cover cursor-pointer"
                          onClick={(e) => handleProtectedClick(e, market.id)}
                        />

                        <div className="flex flex-col justify-center items-center">
                          <span className="text-[13px] font-semibold uppercase text-[#4f4f4f]">
                            {market.name}
                          </span>
                          <span className="text-[12px] font-semibold uppercase text-primary">
                            {market.last_result
                              ? market.last_result.result
                              : "***-**-***"}
                          </span>
                          <span
                            className={`text-[12px] ${!market.game_on
                              ? "text-orange"
                              : "text-greenLight"
                              }`}
                          >
                            {market.game_on
                              ? "Market is Running"
                              : "Market is Close"}
                          </span>
                        </div>
                        <div
                          className={`w-[50px] h-[50px] text-center font-semibold rounded-full`}
                        >
                          {!market.game_on ? (
                            <div style={{ pointerEvents: "none" }}>
                              <img
                                src={Close_b}
                                alt="Close"
                                className="w-full h-full object-cover cursor-not-allowed"
                              />
                            </div>
                          ) : (
                            <img
                              src={Chat1}
                              alt="Play Now"
                              className="w-full h-full object-cover cursor-pointer"
                              onClick={() => handleChatClick(market)} // Call your function on click
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </Tab.Panel>
            </>
          )}
        </Tab.Panels>
      </Tab.Group>

      {/* Render the authentication modal */}
      <Main isOpen={authModalOpen} toggle={() => setAuthModalOpen(false)} />
    </div>
  );
};

export default KalyanAndDesawar;
